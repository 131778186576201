@use "../../styles/Breakpoints.module.scss";

.full-width-hero-ornament {
  position: absolute !important;
  top: 0;
  left: var(--padding-lr);
  height: 100%;
  width: calc(100% - (var(--padding-lr) * 2));
  z-index: 1;
  img {
    top: 0 !important;
    object-fit: scale-down;
  }
}
.full-width-hero-content {
  position: relative;
  z-index: 2;
  flex: 1;
  display: flex;
}

@media screen and (max-width: Breakpoints.$breakpoint-3) {
  .full-width-hero-ornament img {
    top: 25% !important;
    object-position: top;
  }
}
